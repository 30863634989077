import React from "react";
import CarModels from "@/app/components/CarModels/CarModels";
import CarModelMobile from "@/app/components/CarModels/CarModelsMobile";
import { MainServices } from "@/app/components/HomeComponents/MainServices";
import MainServicesMobile from "@/app/components/HomeComponents/MainServices/MainServicesMobile";
import RacingCars from "@/app/components/HomeComponents/RacingCars";
import BankingLayout from "@/app/components/HomeComponents/ToyotaBank";
import BankingMobile from "@/app/components/HomeComponents/ToyotaBank/BankingMobile";
import BannerSection from "@/app/components/HomeComponents/BannerSection/index";
import { fetchBanners } from "@/app/lib/getData";
import { HomeProps } from "@/app/interface/components";
import { BannerProvider } from "@/app/contexts/BannerContext";
import Head from "next/head";

export async function getStaticProps() {
      try {
            const banners = await fetchBanners();
            
            if (!banners || !banners.calls) {
                  return {
                        props: {
                              bannerImages: [],
                              bannerImagesMobile: [],
                              bannerImagesTablet: [],
                              links: [],
                              calls: [],
                              contents: [],
                              defenseTypes: [],
                              title_contents: [],
                        },
                        revalidate: 60,
                  };
            }
            // Criar array de índices com seus tipos
            const indexesWithTypes = banners.calls
                  .map((call: string, index: number) => {
                        const callArray = call.split(",");
                        // Verifica se tem a rota "/" removendo parâmetros
                        if (callArray.some((item) => item.trim().split('?')[0] === "/")) {
                              return {
                                    index,
                                    type: banners.types[index],
                              };
                        }
                        return null;
                  })
                  .filter((item): item is { index: number; type: string } => item !== null);
            
            // Ordenar os índices baseado no tipo
            const sortedIndexes = indexesWithTypes
                  .sort((a, b) => {
                        const typeOrder = {
                              prioridade: 0,
                              oferta: 1,
                              institucional: 2,
                        };
                        
                        const orderA = typeOrder[a.type as keyof typeof typeOrder] ?? 3;
                        const orderB = typeOrder[b.type as keyof typeof typeOrder] ?? 3;
                        
                        return orderA - orderB;
                  })
                  // Filtrar banners institucionais apenas se já tivermos 4 ou mais banners de outros tipos
                  .filter((item, _, array) => {
                        const nonInstitucionalCount = array.filter(
                              (x) => x.type !== "institucional"
                        ).length;
                        return nonInstitucionalCount >= 4
                              ? item.type !== "institucional"
                              : true;
                  })
                  .map((item) => item.index);
            
            // Usar os índices ordenados para filtrar os dados
            const filteredContents = sortedIndexes.map(
                  (index) => banners.contents[index] || null
            );
            const filteredDefenseTypes = sortedIndexes.map(
                  (index) => banners.defenseTypes[index] || null
            );
            const filteredBannerImages = sortedIndexes.map(
                  (index) => banners.bannerImages[index] || null
            );
            const filteredBannerImagesTablet = sortedIndexes.map(
                  (index) => banners.bannerImagesTablet[index] || null
            );
            const filteredBannerImagesMobile = sortedIndexes.map(
                  (index) => banners.bannerImagesMobile[index] || null
            );
            
            const filteredLinks = sortedIndexes
                  .map((index) =>
                        banners.bannerImages[index] ? banners.links[index] : null
                  )
                  .filter((link) => link !== null);
            
            const filteredTitleContents = sortedIndexes.map(
                  (index) => banners.title_contents?.[index] || null
            );
            const bannerImage =
                  filteredBannerImages[
                        filteredBannerImages.findIndex((item) => item !== null)
                        ];
            const bannerImageTablet =
                  filteredBannerImagesTablet[
                        filteredBannerImagesTablet.findIndex((item) => item !== null)
                        ];
            const bannerImageMobile =
                  filteredBannerImagesMobile[
                        filteredBannerImagesMobile.findIndex((item) => item !== null)
                        ];
            
            const processedCalls = sortedIndexes.map((index) => {
                  const callArray = banners.calls[index].split(",");
                  // Se o único item após trim for "/", mantemos ele
                  if (callArray.length === 1 && callArray[0].trim() === "/") {
                        return "/";
                  }
                  // Caso contrário, filtramos o "/"
                  return callArray
                        .map((call) => call.trim())
                        .filter((call) => call !== "/");
            });
            
            return {
                  props: {
                        bannerImages: filteredBannerImages.filter(Boolean) || [],
                        bannerImagesMobile: filteredBannerImagesMobile.filter(Boolean) || [],
                        bannerImagesTablet: filteredBannerImagesTablet.filter(Boolean) || [],
                        bannerImage,
                        bannerImageTablet,
                        bannerImageMobile,
                        links: filteredLinks || [],
                        calls: processedCalls || [],
                        contents: filteredContents.filter(Boolean) || [],
                        defenseTypes: filteredDefenseTypes.filter(Boolean) || [],
                        title_contents: filteredTitleContents.filter(Boolean) || [],
                  },
                  revalidate: 60,
            };
      } catch (error) {
            return {
                  props: {
                        bannerImages: [],
                        bannerImagesMobile: [],
                        bannerImagesTablet: [],
                        links: [],
                        calls: [],
                        contents: [],
                        defenseTypes: [],
                        title_contents: [],
                        bannerImage: "",
                        bannerImageTablet: "",
                        bannerImageMobile: "",
                  },
                  revalidate: 60,
            };
      }
}

export default function Home({
                                   bannerImages,
                                   bannerImagesMobile,
                                   bannerImagesTablet,
                                   bannerImage,
                                   bannerImageTablet,
                                   bannerImageMobile,
                                   links,
                                   calls,
                                   contents,
                                   defenseTypes,
                             }: HomeProps) {
      return (
            <>
                  <Head>
                        <link
                              rel="preload"
                              fetchPriority="high"
                              as="image"
                              href={bannerImage}
                              media="(min-width: 1140px)"
                        />
                        <link
                              rel="preload"
                              fetchPriority="high"
                              as="image"
                              href={bannerImageTablet}
                              media="(min-width: 540px) and (max-width: 1139px)"
                        />
                        <link
                              rel="preload"
                              fetchPriority="high"
                              as="image"
                              href={bannerImageMobile}
                              media="(max-width: 539px)"
                        />
                  </Head>
                  <main>
                        <BannerProvider contents={contents} defenseTypes={defenseTypes}>
                              <BannerSection
                                    setChatbotOpen={() => {}}
                                    setIsConditionsModalOpen={() => {}}
                                    setConditionsContent={() => {}}
                                    links={links}
                                    calls={calls}
                                    bannerImages={bannerImages}
                                    bannerImagesMobile={bannerImagesMobile}
                                    bannerImagesTablet={bannerImagesTablet}
                              />
                        </BannerProvider>
                        <div className="max-lg:hidden">
                              <CarModels />
                              <RacingCars />
                              <MainServices />
                              <BankingLayout />
                        </div>
                        <div className="lg:hidden">
                              <CarModelMobile />
                              <MainServicesMobile />
                              <BankingMobile />
                        </div>
                  </main>
            </>
      );
}
